import Hot from './assets/hot.svg';
import Webcam from './assets/webcam.svg';
import CollectsPii from './assets/pii.svg';
import InProgress from './assets/in_progress.svg';
import New from './assets/new.svg';
import Broken from './assets/broken.svg';
import Recontact from './assets/recontact.svg';
import Explicit from './assets/explicit.svg';
import Sensitive from './assets/sensitive.svg';
import Exclusive from './assets/exclusive.svg';
import Expires from './assets/expires.svg';
import WithDeal from './assets/with_deal.svg';
import Easy from './assets/easy.svg';
import LimitedTime from './assets/limited_time.svg';
import FastPaying from './assets/fast_paying.svg';
import IncreasedReward from './assets/increased_reward.svg';
import GameEasy from './assets/game_easy.svg';
import GameMedium from './assets/game_medium.svg';
import GameHard from './assets/game_hard.svg';
export interface TagConfig {
  Icon: React.FunctionComponent<{ className?: string }>;
  title: string;
  description: string;
  detailedDescription?: string;
}
export type TagConfigs = { [key: string]: TagConfig };
const surveyConfigs: TagConfigs = {
  hot: {
    Icon: Hot,
    title: 'Hot survey!',
    description:
      'This survey is perfect for your profile. Qmee will always find the best surveys for you.',
    detailedDescription:
      "Hot Surveys are our pick of the hottest surveys right now! They don't stick around for long, so make sure you watch out for them and take part right away.",
  },
  webcam: {
    Icon: Webcam,
    title: 'Webcam survey',
    description: 'Requires a webcam.',
    detailedDescription:
      "Webcam surveys will ask you to use your webcam when participating in the survey. We understand that not everyone will want to use their webcam - you can disable webcam surveys (so you won't be sent any that ask to use your webcam) by clicking the webcam icon on your 'Surveys' tab.",
  },
  collects_pii: {
    Icon: CollectsPii,
    title: 'PII survey',
    description: 'Requires personally identifiable information like name or email.',
    detailedDescription:
      "This icon is for surveys that will request Personal Identifiable Information (PII) from you. PII includes your name, email address and phone number. If you're not comfortable sharing this information, you can turn these surveys off by clicking the PII Surveys icon (next to the webcam icon) on your 'Surveys' tab. You should only participate in surveys that ask for your personal information if you are completely comfortable with the collection and usage of this data.",
  },
  restart_allowed: {
    Icon: InProgress,
    title: 'Restartable',
    description:
      'This survey can be continued at a later point, it does not need to be completed in one go',
  },
  new: {
    Icon: New,
    title: 'New survey',
    description: 'This is your chance to get in before anyone else does.',
  },
  broken: {
    Icon: Broken,
    title: 'Broken survey',
    description: 'We have disabled this survey because it was reported as broken.',
  },
  recontact: {
    Icon: Recontact,
    title: 'Recontact survey',
    description: 'This is a follow up survey for one of your previously completed surveys.',
    detailedDescription:
      'Recontact surveys are follow-up surveys to one that you previously successfully completed.',
  },
  explicit: {
    Icon: Explicit,
    title: 'Explicit survey',
    description:
      'This survey contains explicit content that may be uncomfortable for some people. Please only take part in this survey if you are happy with this. User discretion is advised.',
  },
  sensitive: {
    Icon: Sensitive,
    title: 'Sensitive survey',
    description:
      'This survey contains content that may be uncomfortable for some people. User discretion is advised.',
  },
};

const cashbackConfigs: TagConfigs = {
  hot: {
    Icon: Hot,
    title: 'Hot offer!',
    description: "This means that it's one of our top picks - check it out!",
  },
  new: {
    Icon: New,
    title: 'New offer',
    description: 'Brand new offer - check it out!',
  },
  exclusive: {
    Icon: Exclusive,
    title: 'Qmee exclusive!',
    description: 'This is an offer exclusive to Qmee - take a look!',
  },
  limited_time: {
    Icon: LimitedTime,
    title: 'Limited time!',
    description: 'This offer will only be here for a limited time - tick tock!',
  },
  increased_reward: {
    Icon: IncreasedReward,
    title: 'Higher payout!',
    description: 'This reward amount has been increased - ka-ching!',
  },
  fast_paying: {
    Icon: FastPaying,
    title: 'Rapid reward!',
    description: 'This offer pays out faster than normal offers - wow!',
  },
  expires: {
    Icon: Expires,
    title: 'Expires soon!',
    description: "This offer will expire soon - time's ticking!",
  },
  with_deal: {
    Icon: WithDeal,
    title: 'Earn & save!',
    description: 'You can use a coupon code with this cashback offer - yay!',
  },
  easy: {
    Icon: Easy,
    title: 'Easy offer!',
    description: 'This offer is super easy to complete - have a peek!',
  },
  game_easy: {
    Icon: GameEasy,
    title: 'Difficulty - Easy',
    description: 'No sweat! You can complete this offer with minimal effort.',
  },
  game_medium: {
    Icon: GameMedium,
    title: 'Difficulty - Medium',
    description: 'Challenge yourself! This offer may take a bit of effort to complete.',
  },
  game_hard: {
    Icon: GameHard,
    title: 'Difficulty - Hard',
    description: 'No game, no gain! This offer needs a fair amount of effort to complete.',
  },
};

const dealConfigs: TagConfigs = {
  hot: {
    Icon: Hot,
    title: 'Hot deal!',
    description: "This means that it's one of our top picks - check it out!",
  },
  new: {
    Icon: New,
    title: 'New deal',
    description: 'Brand new deal - check it out!',
  },
  expires: {
    Icon: Expires,
    title: 'Expires soon!',
    description: "This deal will expire soon - time's ticking!",
  },
  exclusive: {
    Icon: Exclusive,
    title: 'Qmee exclusive!',
    description: 'This is a deal exclusive to Qmee - take a look!',
  },
};

const tagConfigs = {
  survey: surveyConfigs,
  cashback: cashbackConfigs,
  deal: dealConfigs,
};
export default tagConfigs;
